<template>
	<div class="container">
		<div class="content-container mt-3">
			<div class="content-body">
				<div class="loading" v-if="!isContentReady">
					<div class="main">
						<loader-content :isDisplay="true"></loader-content>
					</div>
				</div>
				<template v-if="isContentReady">
					<network-detail :network="networkData"></network-detail>

					<template v-if="networkData.hasSeller">
						<gadget-step-purchase class="mt-4 mb-2" :step="1"></gadget-step-purchase>

						<div class="loading" v-if="!isResultReady">
							<div class="item">
								<loader-content :isDisplay="true"></loader-content>
							</div>
						</div>

						<template v-if="isResultReady">
							<product-box-single
								:product="product"
								:networkId="networkId"
								:mode="constants.ITEM_BOX_TYPE.NETWORK"
								trackingReferrer="network"
								:initStoreFilter="filter"
								:toggleModalStore="autoToggleStore">
							</product-box-single>
						</template>
					</template>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import Constants from '@/variables/constants';
import NetworkService from '@/services/networkService';
import MixinGeolocationContent from '@/mixins/geolocationContent';
import NetworkDetail from '@/components/network/NetworkDetail';
import ProductBoxSingle from '@/components/product/ProductBoxSingle';
import GadgetStepPurchase from '@/components/gadget/StepPurchase';

export default {
	mixins: [ MixinGeolocationContent ],
	components: {
		NetworkDetail,
		ProductBoxSingle,
		GadgetStepPurchase
	},
	data() {
		return {
			pageTitle: '',
			metaDesc: '',
			constants: Constants,
			isContentReady: false,
			isResultReady: false,
			filter: null,
			networkData: null,
			networkId: Helper.splitDotParam(this.$route.params.key),
			product: null,
			autoToggleStore: null,
			canonicalUrl: ''
		};
	},
	async mounted() {
		// Check Auto Modal Store Display
		this.checkAutoModalStore();

		// Parallel async loading
		await Promise.all([this.getContent(), this.getProductResult()]);

		// SEO
		const productTitle = [ 'ยางรถยนต์', this.product.brand, 'รุ่น', this.product.name, this.product.size ].join(' ');
		this.pageTitle = this.networkData ? this.networkData.name + ' - ' + productTitle + ' | YELLOWTiRE' : Helper.metaDefault().title;
		this.metaDesc = this.networkData ? this.networkData.slogan + ' - ' + productTitle : Helper.metaDefault().desc;
    },
    methods: {
		checkAutoModalStore() {
			const install = Helper.getQueryString('install');
			const delivery = Helper.getQueryString('delivery');

			this.autoToggleStore = {
				isShowInstall: install === '1' ? true : false,
				isShowDelivery: (!install || install === '0') && delivery === '1' ? true : false
			}
		},
        async getContent() {
            this.isContentReady = false;

			const param = this.setupParamNetwork();
            const result = await NetworkService.getNetworkDetail(param);

			if (result.data) {
				this.filter = result.data.filter.store;
				this.networkData = result.data.networkData;
			}

			this.isContentReady = true;
        },
		async getProductResult() {
			// Force to change back mode
			this.searchMode = Constants.NETWORK_SEARCH_MODE.TIRE;

			this.isResultReady = false;

			const param = this.setupParamProduct();
            const result = await NetworkService.getSingleProduct(param);

			if (result.data.resultList.length > 0) {
				this.product = result.data.resultList[0];
			} else {
				this.$router.push('/404');
			}

            this.isResultReady = true;
		},
		setupParamNetwork() {
			const param = {
				key: Helper.splitDotParam(this.$route.params.key),
				urlReferrer: document.referrer
			};

			return param;
		},
		setupParamProduct() {
			const param = {
				page: this.activePage,
				network: Helper.splitDotParam(this.$route.params.key),
				productId: this.$route.params.pid
			};

			return param;
		}
    },
	metaInfo() {
		const image = this.networkData ? this.networkData.logo : location.origin + Helper.metaDefault().logo;
		
        return {
            title: this.pageTitle,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: this.metaDesc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: this.pageTitle },
                { vmid: 'og-desc', property: 'og:description', content: this.metaDesc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: this.metaDesc },
                { vmid: 'twitter-title', property: 'twitter:title', content: this.pageTitle },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ],
			link: [
				{ rel: 'canonical', href: this.canonicalUrl }
			]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.list {
	position: relative;

	@media only screen and (max-width: $screenExtraLarge) {
        padding-top: 20px;
    }	

	.loading {
		.item {
			height: 250px;
		}
	}
}
</style>